import request from '@/utils/request'

// 查询一览  客户经理
export function listPage(data) {
  return request({
    url: '/customerManager/listPage',
    method: 'get',
    params: data
  })
}

// 查询详情 客户经理
export function listById(data) {
  return request({
    url: '/customerManager/listById',
    method: 'get',
    params: data
  })
}

// 新增 客户经理
export function save(data) {
  return request({
    url: '/customerManager/save',
    method: 'post',
    data
  })
}

// 修改 客户经理
export function update(data) {
  return request({
    url: '/customerManager/update',
    method: 'put',
    data
  })
}

// 冻结、解冻 客户经理
export function notFreeze(data) {
  return request({
    url: '/customerManager/notFreeze',
    method: 'get',
    params: data
  })
}

// 查询一览  客户经理关联企业
export function listCompanyPage(data) {
  return request({
    url: '/customerManager/listCompanyPage',
    method: 'get',
    params: data
  })
}

// 客户经理关联企业头部数据  客户经理关联企业
export function listCustomerCount(data) {
  return request({
    url: '/customerManager/listCustomerCount',
    method: 'get',
    params: data
  })
}

